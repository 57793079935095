import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 3,
    tokenName: 'SPADE',
    stakingTokenName: 'SANDMAN',
    stakingTokenAddress: '0x2c754544b56cfc9f57E2d370c598775d57C741cc',
    contractAddress: {
      137: '0x65D025639D11F398bDd21cb47e3deD2C89229b26',
    },
    lpAddress: {
      137: '0xcccec4a90b3435065f5e1fec6346be9da1b7b5ed',
    },
    tokenAddress: {
      137: '0x491E16E53aAe7dF211cB586337A955f223A71F03',
      // 137: '0xf5ea626334037a2cf0155d49ea6462fddc6eff19',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://polygonfarm.finance/',
    harvest: true,
    tokenPerBlock: '0.063516260162602',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 5000,
    withdrawLockup: 72,
    harvestLockup: 1,
    partnerColor: '119, 81, 216',
  },
  {
    sousId: 2,
    tokenName: 'CRYSTL',
    stakingTokenName: 'SANDMAN',
    stakingTokenAddress: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    contractAddress: {
      137: '0xB2cB1D7625f65C7BCc137Cd4c82A847a72Bfc395',
    },
    lpAddress: {
      137: '0xB8e54c9Ea1616beEBe11505a419DD8dF1000E02a',
    },
    tokenAddress: {
      137: '0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://polycrystal.finance/',
    harvest: true,
    tokenPerBlock: '0.063516260162602',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 5000,
    withdrawLockup: 72,
    harvestLockup: 1,
    partnerColor: '216, 148, 214',
  },
  // {
  //   sousId: 1,
  //   tokenName: 'DAI',
  //   stakingTokenName: 'MORPHEUS',
  //   stakingTokenAddress: '0x0D30a539F0597F01cA34356515318a4Fb6Ffe7CB',
  //   contractAddress: {
  //     137: '0xc3d8DAE26E105d26Bef5E27362D260a41664729a',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://polycrystal.finance/',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 2,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
